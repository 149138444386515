<template>
  <div class="meeting-item">

  <div v-for="(meeting, i) in meetings" :key="`meeting-${i}`">
      <div class="d-flex align-center justify-space-between dates ml-2 grey lighten-4 py-3" @click.stop="dateSelected($event, date, togglePopover)">
        <div class="flex-grow-0 date text-center mr-4 pl-3">
          <div class="month">{{$date(meeting.final_date).local().format("MMM")}}</div>
          <div class="day">{{$date(meeting.final_date).local().format("D")}}</div>
          <!-- {{$date(meeting.final_date).format("YYYY")}} -->
        </div>
        <div class="flex-grow-1 px-2" @click.stop="dateSelected($event, date, togglePopover)">
          <div class="top-line">
            <strong>{{findGuest(meeting.attendees)}}</strong>
            <!-- <strong>{{$date.utc(meeting.final_date).local().format('ddd')}}, {{$date.utc(meeting.final_date).local().format("h:mm a")}} to {{$date.utc(meeting.final_date).add(1, 'hour').local().format("h:mm a")}}</strong> -->
          </div>
          <div class="bottom-line grey--text ">
            <span>{{loadedUser.data.firstName}} </span>
            <span>{{$date.utc(meeting.final_date).local().format("h:mm a")}} to {{$date.utc(meeting.final_date).add(1, 'hour').local().format("h:mm a")}}</span>
          </div>
        </div>
        <div class="flex-grow-0 px-3"  @click.stop="removeDate(date, hidePopover)">
          <v-icon>mdi-close-circle-outline</v-icon>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name:'meeting-item',
  props:['meetings'],
  data: () => ({
    findGuest(attendees){
    return attendees.find(user => user.userId !== this.userId);
    }
  }),
}
</script>

<style lang="css" >

</style>
